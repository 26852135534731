
    import Vue from "vue";
    import Component from "vue-class-component";
    import store from "@/store/store";
    import utils from "@/stuff/Utils";
    import { UserRole } from "@/model/Enums";
    import { User } from "@/model/User";
    import { ILookupItem } from '../model/LookupItem';
    import ChangePasswordDialogue from "@/components/ChangePasswordDialogue.vue";

    @Component({
        components: { ChangePasswordDialogue }
    })
    export default class Profile extends Vue {

        user = new User();

        async mounted() {
            if(store.state.signedInUser == null) {
                await store.dispatch("loadSignedInUser");
            }
            if(store.state.signedInUser !== null) {
                this.user = store.state.signedInUser;
            }
        }

        private get roleList(): Array<ILookupItem> { return utils.enumToLookups(UserRole); }
        private get roleOptions(): Array<ILookupItem> { return utils.selectOptions(utils.enumToLookups(UserRole)); }

        private get roleText() {
            return this.roleList.filter(l => l.id === this.user?.role)[0].description;
        }

        showChangePasswordDialogue() {
            const dialogue: ChangePasswordDialogue = this.$refs.changePassword as ChangePasswordDialogue;
            dialogue.show();
        }
    }
